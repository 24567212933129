import React, { useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { useTranslation } from "react-i18next";
import SEO from "root/components/SEO";
import Layout from "root/components/Layout";
import Navbar from "root/components/Navbar";
import Hero from "root/sections/SolutionHero";
import SolutionValuePropositions from "root/sections/SolutionValuePropositions";
import SolutionTestimonials from "root/sections/Testimonials";
import SolutionInstitutions from "root/sections/SolutionInstitutions";
import SolutionKnowMore from "root/sections/SolutionKnowMore";
import Footer from "root/components/Footer";
import Apoios from "root/components/Apoios";
import ShuffleArray from "../../components/ShuffleArray";

/* eslint-disable id-length */
const SolutionInstitutionPage = () => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage("pt-PT");
  }, []);

  const testimonials = ShuffleArray(t("testimonials").testimonials);

  const data = useStaticQuery(graphql`
    query {
      values01: file(relativePath: { eq: "valuesInstitution01.png" }) {
        childImageSharp {
          fluid(maxWidth: 707, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      values1_mobile: file(relativePath: { eq: "valuesInstitution1_m.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      iconTv: file(relativePath: { eq: "icon_tv.png" }) {
        childImageSharp {
          fluid(maxWidth: 39, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      iconReligion: file(relativePath: { eq: "icon_religion.png" }) {
        childImageSharp {
          fluid(maxWidth: 39, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      iconPhoto: file(relativePath: { eq: "icon_photo.png" }) {
        childImageSharp {
          fluid(maxWidth: 39, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      iconNews: file(relativePath: { eq: "icon_news.png" }) {
        childImageSharp {
          fluid(maxWidth: 39, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      iconMusic: file(relativePath: { eq: "icon_music.png" }) {
        childImageSharp {
          fluid(maxWidth: 39, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      iconGames: file(relativePath: { eq: "icon_games.png" }) {
        childImageSharp {
          fluid(maxWidth: 39, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      iconDraw: file(relativePath: { eq: "icon_draw.png" }) {
        childImageSharp {
          fluid(maxWidth: 39, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      iconPhone: file(relativePath: { eq: "icon_phone.png" }) {
        childImageSharp {
          fluid(maxWidth: 39, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      hero: file(relativePath: { eq: "All in One siosLIFE_4.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      wheelChairDT: file(relativePath: { eq: "wheelChair DT.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      values2: file(relativePath: { eq: "professionals.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      values3: file(relativePath: { eq: "familias e funcionalidades.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      heroMobile: file(relativePath: { eq: "All in One siosLIFE_2.png" }) {
        childImageSharp {
          fluid(maxWidth: 1024, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      specifications1: file(relativePath: { eq: "specifications-mobile.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      specifications2: file(relativePath: { eq: "home-values1.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      testimonial: file(relativePath: { eq: "testimonial.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      knowMore: file(relativePath: { eq: "knowMore.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      knowMoreGrid: file(relativePath: { eq: "knowMoreGrid.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const valuePropositionImages = {
    professionals: data.values2,
    values3: data.values3,
    values1: data.values01,
    values1_m: data.values1_mobile,
    wheelChairDT: data.wheelChairDT,
    iconGames: data.iconGames,
    iconDraw: data.iconDraw,
    iconMusic: data.iconMusic,
    iconNews: data.iconNews,
    iconPhone: data.iconPhone,
    iconPhoto: data.iconPhoto,
    iconReligion: data.iconReligion,
    iconTv: data.iconTv,
  };

  return (
    <div>
      <SEO title={t("pages.solutionInstitution.label")} keywords="" />
      <Layout>
        <Navbar
          color="blue"
          currentPage="solutionInstitution"
          buttonColor="red"
          blueLogin="true"
        />
        <Hero
          image={data.hero}
          heroStyles={{
            width: { width: "855px" },
            right: { right: "-240px" },
            mobileWidth: { maxWidth: "970px" },
          }}
          color="red"
          translations={t("solutionInstitution.hero")}
        />
        <SolutionValuePropositions
          translations={t("solutionInstitution.valuePropositions")}
          color="red"
          images={valuePropositionImages}
        />
        <SolutionKnowMore
          translations={t("solutionInstitution.knowMore")}
          image={data.knowMore}
          color="red"
          knowMoreGrid={data.knowMoreGrid}
        />
        <SolutionTestimonials
          color="red"
          videoUrl="https://www.youtube.com/embed/fLB4i9SpJN8"
          image={data}
          testimonials={testimonials}
        />
        <SolutionInstitutions color="red" />
        <Footer />
        <Apoios />
      </Layout>
    </div>
  );
};
/* eslint-enable id-length */

export default SolutionInstitutionPage;
